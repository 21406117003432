import './about.css';
import wooman from '../../img/wooman.png';
import fagots from '../../img/fagots.png';
import dipploma from '../../img/diploma.png';
import vanilasky from '../../img/vanilasky.png';
import { Card } from '../../components/card/card';

function About() {
    const cardsData = [
        {
            img: wooman,
            header: 'О влечении к женскому полу',
            description: 'Когда все оттачивали мастерство поцелуя на помидорах, гена пошел дальше и всегда выбирал что-то более приближенное к реальным женским губам. Как жаль, что до реальных женских губ дойти так и не удалось :('
        },
        {
            img: dipploma,
            header: 'Образование',
            description: 'По образованию Гена плиточник-облицовщик, однако поступить на профессию удалось не с первого раза. Гена не отчаивался и все-таки смог получить заслуженный диплом плиточника. Однако в регистратуре все перепутали и отдали мальчика другим родителям.'
        },
        {
            img: fagots,
            header: 'Спорт и хобби',
            description: '“Футбольчик! Мальчики походят на кочков!” - именно под эту песню Гена едет на очередную футбольную тренировку с коллегами на ФОЦ “Машерова”. Гена любит футбол, как чебурашка любит крокодил, поэтому когда-нибудь зависимость Гены от футбола его погубит. В буквальном смысле сгниет :('
        },
        {
            img: vanilasky,
            header: 'Ванильное небо',
            description: '"Не думай о секундах свысока" - сказал Гена, попивая ванильный раф и докуривая метоловый кент. Все это время он думал лишь только о ней. О любимой ст.м. Площадь Победы, где он любит гулять вместо работы и фотографировать закат для сториз'
        }
    ];

    return (
        <div className="about-page">
            <h1 className="h1 about-page__header">
                Биография Геннадия Бородуна
            </h1>
            {cardsData.map((card, index) => {
                return (
                    <Card
                        key={`card-${index}`}
                        img={card.img}
                        reverce={index % 2 !== 0}
                        content={
                            <div className="card__wrap">
                                <div className="card__header">
                                    {card.header}
                                </div>
                                <div className="card__description">
                                    {card.description}
                                </div>
                            </div>
                        }
                    />
                )
            })}
        </div>
    );
}

export default About;