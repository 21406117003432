import './feedback.css';
import { useState } from 'react';

export function Feedback (props) {
  const [sended, setSended] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);

  function sender (event) {
    if (!!localStorage.getItem("setSendStatus")) {
      setSendStatus(localStorage.getItem("setSendStatus"))
      return false
    }
    event.preventDefault()
    const $target = event.target.closest('button[type="submit"]');
    const $parent = $target.closest('.js-form');
    const $fields = [
      ...$parent.querySelectorAll('input'),
      ...$parent.querySelectorAll('textarea')
    ];
    const formData = new FormData();
    const date = new Date();
    $fields.forEach(
      $field => formData.append(
        $field.name,
        `{
          "value": "${$field.value}",
          "placeholder": "${$field.placeholder.toLowerCase()}"
        }`
      )
    );
    formData.append("date", `{
      "value": "${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2,0)}-${date.getDate().toString().padStart(2,0)} ${date.getHours().toString().padStart(2,0)}:${date.getMinutes().toString().padStart(2,0)}:${date.getSeconds().toString().padStart(2,0)}"
    }`);
    if (!sended) {
      setSended(true);
      fetch(
        '/api/v1/send_review/',
        {
          method: 'POST',
          body: formData
        }
      )
        .then(response => response.json())
        .then(commits => {
          setSended(false);
          if (commits.submited) {
            localStorage.setItem("setSendStatus", commits.submited);
            setSendStatus(commits.submited);
          }
          return commits;
        });
    }
  }

  if (sendStatus) {
    return (
      <form className="feedback-form js-form">
        <h1 className="feedback-form__header">
         Вы уже оставили поздраление.
        </h1>
        <p className="feedback-form__paragraph">
          Спасибо за то, что сжалились над бедным, потным и грустным добряком.
        </p>
      </form>
    )
  } else {
    return (
      <form className="feedback-form js-form">
        <h1 className="feedback-form__header"
          dangerouslySetInnerHTML={{ __html: props.header }}>
        </h1>
        <p className="feedback-form__paragraph"
          dangerouslySetInnerHTML={{ __html: props.paragraph }}>
        </p>

        <input id="name" name="name" placeholder="Имя или никнейм" type="text"/>
        <textarea id="descruption" name="descruption" placeholder="Позравление" rows="4"/>

        <button
          type="submit"
          onClick={sender}
          className="btn btn--bordered feedback-form__submiter"
        >
          отправить поздравление
        </button>
      </form>
    )
  }
}