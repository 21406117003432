import './footer.css';
import { Social } from '../social/social';
import { useLocation } from 'react-router-dom'

export function Footer () {
    const location = useLocation();
    if (location.pathname !== '/') {
        return (
            <footer className='footer'>
                <Social/>
                <p className="footer__copyright">
                    1998 - {new Date().getFullYear()}  © {window.location.host} 18+ 
                </p>
            </footer>
        )
    }
}