import './social.css';
import instagram from '../../img/instagram.svg';
import telegram from '../../img/telegram.svg';
import linkidin from '../../img/linkidin.svg';
import vkontakte from '../../img/vkontakte.svg';

export function Social () {
    return (
        <ul className="social">
            <li className="social__link-wrap">
                <a href="https://instagram.com/genaborodun
" target="_blank" className="social__link">
                    <img src={instagram} className="social__logo"/>
                    <label className="social__label">Instagram</label>
                </a>
            </li>
            <li className="social__link-wrap">
                <a href="https://t.me/genaborodun
" target="_blank" className="social__link">
                    <img src={telegram} className="social__logo"/>
                    <label className="social__label">Telegram</label>
                </a>
            </li>
            <li className="social__link-wrap">
                <a href="https://www.linkedin.com/in/henadzi-borodun
" target="_blank" className="social__link">
                    <img src={linkidin} className="social__logo"/>
                    <label className="social__label">LinkedIn</label>
                </a>
            </li>
            <li className="social__link-wrap">
                <a href="https://vk.com/genaborodun" target="_blank" className="social__link">
                    <img src={vkontakte} className="social__logo"/>
                    <label className="social__label">Vkontakte</label>
                </a>
            </li>
        </ul>
    )
}