import './header.css';
import logo from '../../img/logo.svg';
import menu from '../../img/menu.svg';
import card from '../../img/card.png';
import close from '../../img/close.svg';
import rainbow from '../../img/rainbow.png';
import { Social } from '../social/social';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export function Header () {
    const [mobileMenuStatus, setMobileMenuStatus] = useState(false);
    const headerClasses = `header${mobileMenuStatus ? ' open' : ''}`;

    return (
        <header className={headerClasses}>
            <div className="neader__dimer" onClick={() => setMobileMenuStatus(false)}/>
            <button className="header__mobile" onClick={() => setMobileMenuStatus(true)}>
                <img src={menu} className="header__logo" alt='logo'/>
            </button>
            <div className="header__nav-wrap">
                <button className="header__nav-close" onClick={() => setMobileMenuStatus(false)}>
                    <img src={close} className="header__logo" alt='logo'/>
                </button>
                <nav className="header__nav">
                    <ul className="header__list">
                        <li className="header__link-wrap">
                            <Link to="/about" onClick={() => setMobileMenuStatus(false)}>О Гене</Link>
                        </li>
                        <li className="header__link-wrap">
                            <Link to="/reviews" onClick={() => setMobileMenuStatus(false)}>Ваши поздравления</Link>
                        </li>
                        <li className="header__link-wrap">
                            <Link to="/send" onClick={() => setMobileMenuStatus(false)}>Поздравить с ДР</Link>
                        </li>
                    </ul>
                </nav>
                <div className="header__nav_footer">
                    <img src={card} className="header__card" alt='card visual'/>
                    <Social/>
                </div>
            </div>
            <Link to="/" className="header__home-link">
                <img src={logo} alt='logo'/>
                <img src={rainbow} alt='rainbow'/>
            </Link>
        </header>
    )
}