import React from 'react';
import './App.css';
import { Header } from './components/header/header'
import { Footer } from './components/footer/footer'
import Home from './pages/home/home'
import Send from './pages/send/send'
import About from './pages/about/about'
import Reviews from './pages/reviews/reviews'
import Page404 from './pages/page404/page404'
import { Routes, Route, Outlet, useLocation, Navigate } from 'react-router-dom'

function App() {
  const location = useLocation();
  return (
    <div className={`App${location.pathname === '/' ? ' homepage' : ''}`}>
      <Header/>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route exact index element={<Home />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="reviews" element={<Reviews />} />
          <Route exact path="send" element={<Send />} />
          <Route exact path="page-404" element={<Page404 />} />
          <Route exact path="*" element={<Navigate to="/page-404"/>} />
        </Route>
      </Routes>
      <Footer/>
    </div>
  );
}

function Layout() {
  const location = useLocation();
  const mainClasses = `${location.pathname.slice(1) || 'homepage'}`;
  return (
    <main className={mainClasses}>
      <Outlet/>
    </main>
  );
}

export default App;