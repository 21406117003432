import './home.css';
import gena from '../../img/gena.png';
import card from '../../img/card.png';
import { Link } from 'react-router-dom';
import { Social } from '../../components/social/social';

function Home() {
    return (
        <div className="home-page">
            <div className="home-page__left">
                <h1 className="home-page__header">
                    Гена - как много в&nbsp;этом слове
                </h1>
                <p className="home-page__quote">
                    “Путь самурая сложен и тернист. Именно поэтому я после 11-го пошел в лицей на плиточника, а потом на ФМО”.
                </p>
                <div className="home-page__left-footer">
                    <Link to="/about" className="btn btn--default">
                        Биография Гены
                    </Link>
                    <p className="home-page__author">© Бородун Геннадий</p>
                </div>
                <Social/>
            </div>
            <div className="home-page__right">
                <img className="home-page__gena" src={gena}/>
                <img className="home-page__card" src={card}/>
            </div>
        </div>
    );
}

export default Home;