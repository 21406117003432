import './send.css';
import vologiyGena from '../../img/vologiy-gena.png';
import { Card } from '../../components/card/card';
import { Feedback } from '../../components/form/feedback';

function Send() {
    const cardData = {
        img: vologiyGena,
        header: 'Оставьте поздраление.',
        description: 'Спасибо за то, что сжалились над бедным, потным и грустным добряком. С каждым поздравлением Гена получает 0,01&nbsp;❤️.'
    };

    return (
        <div className="send-page">
            <Card
                img={cardData.img}
                reverce={true}
                content={
                    <Feedback header={cardData.header} paragraph={cardData.description}/>
                }
            />
        </div>
    );
}

export default Send;