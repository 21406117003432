import './reviews.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '../../components/card/card';

function Reviews() {
    const [products, setProducts] = useState(false);
    if (!products) {
        setProducts([]);
        if (window.location.host.includes('localhost')) {
            setProducts([
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                },
                {
                    date: '14.12.2023',
                    time: '12:30',
                    author: 'Satan',
                    comments: 'Test info by view'
                }
            ]);
        } else {
            fetch(
                '/api/v1/reviews/'
            )
                .then(response => response.json())
                .then(commits => {
                    setProducts(commits);
                });
        }
    }

    console.log(products)
    const nowDate = new Date();
    const minDate = new Date(`${nowDate.getFullYear()}-12-25 00:00:01`);
    const maxDate = new Date(`${nowDate.getFullYear() + 1}-12-24 23:59:59`);

    if (products && products.length) {
        if (nowDate.getTime() < minDate.getTime() || nowDate.getTime() > maxDate.getTime()) {
            return (
                <div className="reviews-page">
                    <h1 className="h1 reviews-page__header">
                        Поздраления есть, но...
                    </h1>
                    <p className="reviews-page__description">
                        За {nowDate.getFullYear()} год поздравления уже присутствуют,
                        не переживайте, скоро они будут доступны. Увидеть их можно
                        буедет весьма скоро. Случится отображение поздравлений уже
                        25 декабря в 00:00:00 за {nowDate.getFullYear()} год. В следующем
                        году данная акция может повториться, но не обещаем.
                    </p>
                </div>
            );
        } else {
            return (
                <div className="reviews-page">
                    <h1 className="h1 reviews-page__header">
                        Ваши поздраления для Геннадия
                    </h1>
                    <p className="reviews-page__description">
                        Тут можно увидеть поздраления, посмотреть кто уже принял
                        участие в поддержке ДР Гены, а также считает, что достойнее людей нет
                    </p>
                    <div className="reviews__cards">
                        {products.map((slide, index) => {
                            return (
                                <Card
                                    key={`review-${index}`}
                                    cssClass='card--review'
                                    content={
                                        <div className="card__wrap">
                                            <div className="card__header">
                                                <label className="card__label">
                                                    Автор:
                                                </label>
                                                {` ${slide.author}`}
                                            </div>
                                            <div className="card__description">
                                                {slide.comment}
                                            </div>
                                            <div className="card__footer">
                                                <div>
                                                    <label className="card__label">
                                                        Дата:
                                                    </label>
                                                    <span className="reviews-page__contact-info">
                                                        {` ${slide.date}`}
                                                    </span>
                                                </div>

                                                <div>
                                                    <label className="card__label">
                                                        Время:
                                                    </label>
                                                    <span className="reviews-page__contact-info">
                                                        {` ${slide.time}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                            />
                            )   
                        })}
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="reviews-page">
                <h1 className="h1 reviews-page__header">
                    Ваших поздралений еще нет
                </h1>
                <p className="reviews-page__description">
                    Тут можно было бы увидеть поздраления,
                    посмотреть кто уже принял участие в поддержке ДР&nbsp;Гены,
                    а также считает, что достойнее людей нет.
                    Но увы и ах, нет еще пока что поздравлений.
                    Не стоит сильно переживать - будь первым или первой в этой задаче.
                    Все не так уж и тяжело, клац по кнопке
                    "Поздравить Гену с ДР" ниже и перейди к форме.
                </p>
                <p className="reviews-page__description">
                    <Link to="/send" className="btn btn--default">Поздравить Гену с ДР</Link>
                </p>
            </div>
        );
    }
}

export default Reviews;