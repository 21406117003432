import './card.css';

export function Card (props) {
    const subCalss = `${props.cssClass ? ' ' + props.cssClass : ''}`
    const cardClasses = `card${props.reverce ? ' card--reverse' : ''}${subCalss}`

    return (
        <div className={cardClasses}>
            <div className="card__text">
                {props.content}
            </div>
            {
                props.img ? 
                    <div className="card__image">
                        <img src={props.img} className="card__img" alt='card visual'/>
                    </div> : ''
            }
        </div>
    )
}